<template>
  <div>
    <br />
    <br />
    <div class="row align-items-end">
      <div class="col-3">
        <label for="">Catégorie</label>
        <select v-model="section_reference" class="form-select">
          <option
            v-for="(section, index) in sections"
            :key="index++"
            :value="section.reference"
          >
            {{ section.fullName }}
          </option>
        </select>
      </div>

      <div class="col-3">
        <label for="">Produit</label>
        <select v-model="production_item.item_reference" class="form-select">
          <option
            v-for="(item, index) in items.filter(
              (p) => p.section_reference == section_reference
            )"
            :key="index++"
            :value="item.reference"
          >
            {{ item.fullName }}
          </option>
        </select>
      </div>

      <div class="col-3">
        <label for=""> Stock <span class="text-danger">*</span> </label>
        <select v-model="production_item.stock_reference" class="form-select">
          <option value="" default>Créer un nouveau stock</option>
          <option
            v-for="(stock, index) in filteredStocks"
            :key="index"
            :value="stock.reference"
            :selected="true"
          >
            {{ stock.fullName }} Quantité : {{ stock.quantity }}
            {{ stock.unit }}
          </option>
        </select>
        <div class="invalid-feedback">Example invalid select feedback</div>
      </div>

      <div class="col">
        <label for=""> Désignation de stock </label>
        <input
          type="text"
          v-model="production_item.stockFullName"
          :disabled="production_item.stock_reference != ''"
          class="form-control"
        />
      </div>
    </div>
    <br />
    <div class="row align-items-end">
      <div class="col-2">
        <label for=""> Quantité </label>
        <input
          type="text"
          v-model="production_item.quantity"
          class="form-control"
        />
      </div>

      <div class="col">
        <label for="">Remarque </label>
        <input
          type="text"
          v-model="production_item.remark"
          class="form-control"
        />
      </div>

      <div class="col-auto">
        <label for=""></label>
        <button class="btn btn-success" @click="save(production_item)">
          <i class="bi bi-plus-circle"></i> Insérer
        </button>
      </div>

      <div class="col-auto">
        <label for=""> </label>
        <button
          class="btn btn-secondary d-flex align-items-center"
          @click="refresh()"
        >
          <i class="bi bi-arrow-clockwise fs-4 me-2"></i>
          <span> Actualiser </span>
        </button>
      </div>
    </div>

    <br />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">Nº</th>
          <th scope="col">Désignation</th>
          <th scope="col">Quantité</th>
          <th scope="col">Stock</th>
          <th scope="col">Créer par</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in production_items" :key="index++">
          <td>
            {{ index }}
          </td>

          <td>
            <span v-if="item.item">
              {{ item.item.fullName }}
            </span>
          </td>

          <td class="text-dark">
            <span v-if="item.quantity != null"> + {{ item.quantity }} </span>

            <span v-if="item.stock != null" class="text-primary">
              ( {{ item.stock.quantity - item.quantity }} en Stock)
            </span>
          </td>

          <td>
            <span v-if="item.stock">
              {{ item.stock.fullName }}
            </span>

            <span v-else-if="item.stockFullName">
              {{ item.stockFullName }} (à créer)
            </span>
          </td>

          <td>
            <span v-if="item.user">
              {{ item.user.name }}
            </span>
          </td>
          <td class="d-flex">
            <button @click="remove(item.id)" class="btn text-danger">
              <i class="bi bi-trash-fill"></i>
            </button>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr class="border-0">
          <td></td>

          <td>Total :</td>
          <td>
            <span
              v-if="totalQuantityItems != null"
              class="fw-bold bg-warning px-2 py-1 rounded"
            >
              {{ totalQuantityItems }}
            </span>
          </td>

          <td></td>
          <td></td>
          <td></td>
        </tr>
      </tfoot>
    </table>
    <br />
    <div class="row mt-3 justify-content-between align-items-center">
      <div class="col-8">
        <div class="form-check align-items-center">
          <input
            type="checkbox"
            id="accept"
            value=""
            :checked="accept ? true : false"
            class="form-check-input me-2"
            @click="accept ? (accept = false) : (accept = true)"
          />
          <label class="form-check-label" for="accept">
            J'accepte que les informations relatives à la production ( Quantité
            totale : <strong>{{ totalQuantityItems }}</strong
            >, Nombre d'articles :

            <strong>{{ numberItems }}</strong> ) et correctes.
            <span class="text-danger">*</span>
          </label>
        </div>
      </div>
      <div class="col-auto">
        <button
          class="btn btn-success"
          @click="validated()"
          :disabled="!accept ? true : false"
          v-if="accept"
        >
          <i class="bi bi-arrow-right-circle"></i> Suivant
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      production_item: {},
      section_reference: "",
      accept: false,
      order_reference: "",
    };
  },
  computed: {
    ...mapGetters("item", {
      items: "getAll",
    }),
    ...mapGetters("section", {
      sections: "getAll",
    }),

    ...mapGetters("production", {
      production_items: "getItems",
      totalQuantityItems: "totalQuantityItems",
      totalPriceTTCItems: "totalPriceTTCItems",
      numberItems: "numberItems",
    }),
    ...mapGetters("order", {
      orders: "getOrderSuppliers",
    }),

    ...mapGetters("stock", {
      stocks: "getStocks",
    }),

    filteredStocks() {
      return this.stocks.filter(
        (stock) => stock.item_reference === this.production_item.item_reference
      );
    },
    isSelectDisabled() {
      return this.filteredStocks.length === 1; // Disable select if there's only one filtered stock
    },
  },
  methods: {
    async save(data) {
      await this.$store.dispatch("production/storeItem", data);
      await (this.production_item = {});
    },
    async remove(id) {
      await this.$store.dispatch("production/destroyItem", id);
    },
    async refresh() {
      this.$store.dispatch("production/getAllItems");
      this.$store.dispatch("stock/getAll");
    },
    async validated() {
      this.$router.push({ name: "productions-create" });
    },
  },
  async beforeMount() {
    await this.$store.dispatch("section/getAll");
    await this.$store.dispatch("item/getAll");
    await this.$store.dispatch("production/getAllItems");
    await this.$store.dispatch("stock/getAll");
  },

  filters: {
    date: function (date) {
      return moment(date).format("DD-MM-yyyy");
    },
  },

  watch: {
    filteredStocks: {
      handler(newStocks) {
        if (newStocks.length > 1) {
          this.production_item.stock_reference = newStocks[0].reference; // Select the first stock
        }
      },
      immediate: true, // Trigger the handler immediately when the component is created
    },
  },
};
</script>
